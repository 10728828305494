import env from "@/config/env";
import { CookiesService } from "@/services";
import Cookies from "js-cookie";
import { create } from "zustand";

export interface IUserProfile {
  email: string;
  firstname: string;
  lastname: string;
  uid: string;
  is_super_admin?: boolean;
  is_switch_account?: boolean;
}

export interface IUserSessionStore {
  isLoggedIn: boolean;
  isConnected: boolean;
  lang: string;
  token: string;
  refreshToken: string;
  role: string;
  profile: Partial<IUserProfile>;
}

export interface IUserSessionSetter {
  setIsLoggedIn: (value: boolean) => void;
  updateState: (value: Partial<IUserSessionStore>) => void;
  updateProfile: (value: Partial<IUserProfile>) => void;
  flush: () => void;
}

const {
  token,
  refreshToken,
  lang,
  email,
  firstname,
  lastname,
  user_uid,
  is_switch_account,
  role,
} = JSON.parse(Cookies.get(env.COOKIE_NAME) ?? "{}");

const initalState = {
  isLoggedIn: !!token,
  token,
  refreshToken,
  lang,
  loading: false,
  isConnected: true,
  profile: {
    email,
    firstname,
    lastname,
    uid: user_uid,
    is_switch_account: !!is_switch_account,
  },
  role,
};

const UserSessionStore = create<IUserSessionStore & IUserSessionSetter>(
  (set) => ({
    ...initalState,
    setIsLoggedIn: (value: boolean) => set({ isLoggedIn: value }),
    updateState: (newValue: any = {}) => set(() => ({ ...newValue })),
    updateProfile: (newValue: any = {}) =>
      set((state) => {
        const updated: any = { profile: { ...state.profile, ...newValue } };
        CookiesService.set({
          firstname: updated.firstname,
          lastname: updated.lastname,
        });
        return updated;
      }),
    flush: () =>
      set({
        isLoggedIn: false,
        profile: {},
        token: "",
        lang: "",
        refreshToken: "",
        role: "",
      }),
  })
);

export default UserSessionStore;
