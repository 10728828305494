import logoDilypse from "@/assets/images/logo-dilypse.png";
import Icon from "@/components/base/Icon";
import BaseInput from "@/components/base/input";
import useNotification from "@/hooks/useNotification";
import useStyles from "@/pages/login/styles";
import { CookiesService, UserSessionService } from "@/services";
import UiService from "@/services/ui";
import ResellerStore from "@/store/reseller";
import UiStore from "@/store/ui";
import UserSessionStore from "@/store/user-session";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

const LoginPage = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { classes } = useStyles();
  const [showMailAdornment, setShowMailAdornment] = useState(false);
  const [showPasswordAdornment, setShowPasswordAdornment] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { resellerLogo, resellerName } = ResellerStore();
  const { isConnected } = UserSessionStore();
  const { preferredLanguage } = UiStore();
  const { notif } = useNotification();
  const [searchParams] = useSearchParams()

  const defaultValue = {
    email: "",
    password: "",
  };

  const schema = z.object({
    email: z.string().email(t("FORMS.ERROR.INVALID_FORMAT_EMAIL")),
    password: z.string().min(8, "").max(1000, ""),
  });

  const { control, handleSubmit } = useForm({
    defaultValues: defaultValue,
    resolver: zodResolver(schema),
    mode: "onSubmit",
  });

  const formValue = useWatch({ control });

  const loginFlow = async (email = "", password = "", authType = "default") => {
    setLoading(true);
    setError("");
    if (!isConnected) {
      setError(t("SYSTEM_ERROR.INVALID_REQUEST"));
      setLoading(false);
    } else {
      const { success, error } = await UserSessionService.login(
        email,
        password,
        authType
      );
      setLoading(false);

      if (error) {
        setError(t(`${error}`));
      } else if (success) {
        const redirectTo = decodeURIComponent(searchParams.get("redirectTo"))
        if (redirectTo !== "null") {
          navigate(redirectTo)
          return
        }
        const redirectLink = CookiesService.get("redirectLink");
        if (redirectLink) {
          navigate(redirectLink);
          CookiesService.delete(["redirectLink"]);
        } else {
          navigate("/accounts");
        }
      }
    }
  };

  const handleLoginSuccess = useCallback(
    async (responseGoogle) => {
      if (responseGoogle?.clientId && responseGoogle?.credential) {
        setLoading(true);
        setError("");
        loginFlow(responseGoogle.clientId, responseGoogle.credential, "google");
      }
    },
    [loginFlow]
  );

  const onSubmit = async (event: any) => {
    setLoading(true);
    setError("");
    if (!isConnected) {
      setError(t("SYSTEM_ERROR.INVALID_REQUEST"));
      setLoading(false);
    } else {
      loginFlow(formValue?.email, formValue?.password);
    }
  };

  useEffect(() => {
    UiService.setState({ title: "LOGIN.TITLE" });
  }, []);

  return (
    <Box className={classes.loginWrapper}>
      {resellerLogo && (
        <img
          data-testid="logo"
          src={resellerLogo || logoDilypse}
          alt={resellerName || "Dilypse"}
        />
      )}
      <Typography data-testid="title" variant="h1">
        {t("LOGIN.TITLE")}
      </Typography>
      {preferredLanguage && (
        <GoogleLogin
          onSuccess={handleLoginSuccess}
          onError={() => {
            notif({
              type: "ERROR",
              message: t(`LOGIN.GOOGLE_AUTH_FAILED`),
            });
          }}
          size="large"
          auto_select={false}
          context="signup"
          locale={preferredLanguage}
          width={"342"}
        />
      )}

      <Stack
        className="separator"
        flexDirection={"row"}
        justifyContent={"center"}
        sx={{
          maxWidth: "360px",
          width: "360px",
          position: "relative",
          "&:before": {
            content: '""',
            position: "absolute",
            top: "12px",
            left: 0,
            height: "2px",
            width: "100%",
            backgroundColor: "rgba(91, 125, 216, 0.16)",
            zIndex: 0,
          },
          "& p": {
            width: "57px",
            backgroundColor: "white",
            display: "block",
            position: "relative",
            zIndex: 2,
            color: "action.active",
          },
          "@media(max-width: 425px)": {
            width: "100%",
          },
        }}
      >
        <Typography>{t("MODAL.OR")}</Typography>
      </Stack>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }) => (
            <BaseInput
              {...field}
              type="email"
              label={t("LABEL.EMAIL")}
              data-testid="email"
              error={!!fieldState?.invalid}
              helperText={fieldState?.error?.message}
              onFocus={() => setShowMailAdornment(true)}
              autoComplete="new-password"
              onBlur={() => {
                setShowMailAdornment(false)
                field.onBlur()
              }}
              startAdornment={(showMailAdornment || field.value) && <Icon name="envelope" />}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <BaseInput
              {...field}
              label={t("LABEL.PASSWORD")}
              type="password"
              value={formValue?.password}
              data-testid="password"
              autoComplete="new-password"
              onFocus={() => setShowPasswordAdornment(true)}
              onBlur={() => {
                setShowPasswordAdornment(false)
                field.onBlur()
              }}
              startAdornment={(showPasswordAdornment || field.value) && <Icon name="lock" />}
            />
          )}
        />
        <Link to={"/forgot-password"}>
          <Typography component={"span"} color="primary">
            {t("LOGIN.FORGOT")}
          </Typography>
        </Link>
        {error && (
          <Typography data-testid="error" component={"span"} className="error">
            {error}
          </Typography>
        )}

        <Button
          variant="contained"
          data-testid="connect"
          type="submit"
          disabled={!(formValue.email.length > 0 && formValue.password.length > 7) || loading}
        >
          {loading && <CircularProgress size={16} />}
          {t("BUTTON.BUTTON_CONNECT")}
        </Button>
      </form>
    </Box>
  );
};

export default LoginPage;
