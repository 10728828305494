import { AppBar, Toolbar, IconButton } from "@mui/material"
import Icon from "../Icon"
import useStyles from "./styles"
import UiStore from "@/store/ui"

const BaseAppBar = () => {
    const { classes } = useStyles()
    const { setShowMenu, showMenu } = UiStore()

    return (
        <AppBar position="static" elevation={0} className={classes.header}>
            <Toolbar role="toolbar">
                <IconButton
                    data-testid="mobile-menu-button"
                    className={"mobile-menu-btn"}
                    onClick={() => {
                        setShowMenu(!showMenu)
                    }}
                >
                    <Icon name="mobileMenu" />
                </IconButton>
            </Toolbar>
        </AppBar>
    )
}

export default BaseAppBar
