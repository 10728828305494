import Icon from "@/components/base/Icon";
import CustomTooltip from "@/components/base/Tooltip";
import {
  labelErrorSx,
  logoSx,
  modalStyle,
} from "@/components/base/modal/style";
import useLogicFile from "@/hooks/upload-file/useLogic";
import { withSxProp } from "@/utils";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import useStyles from "./style";

export interface UploadFileModal {
  title?: string;
  open?: boolean;
  close?: () => void;
  setFile: (newFile: File) => void;
  handleStaticFile?: (imageFile: File) => void;
  children?: ReactNode;
  subTitle: string | JSX.Element;
  accept: string;
  replace: string;
}

const UploadFile = ({
  open,
  close,
  setFile,
  title,
  children,
  subTitle,
  accept,
  handleStaticFile,
  replace,
}: UploadFileModal) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const {
    inputChangeHandler,
    error,
    closeModal,
    isThereStaticFile,
    onAdd,
    labelRefHandler,
    label,
    fileItem,
    handleFile,
  } = useLogicFile({
    setFile,
    close,
    accept,
  });

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper elevation={6} sx={withSxProp(modalStyle, logoSx)}>
        <Box className="title-wrapper">
          <Typography id="modal-modal-title" data-testid="title" variant="h2">
            {title}
          </Typography>
          <CustomTooltip title={t("USERS.CLOSE")}>
            <IconButton onClick={closeModal} className="btn-x">
              <Icon name="x" />
            </IconButton>
          </CustomTooltip>
        </Box>
        <Box sx={{ width: "100%" }}>
          {subTitle && (
            <Typography
              id="modal-sub-title"
              data-testid="sub-title"
              variant="h2"
              data-e2e="add-logo-sub-title"
              sx={{
                width: "100%",
                lineHeight: "initial",
                flexDirection: "column",
                display: "flex",
                gap: "16px",
              }}
              className={isThereStaticFile ? classes.hasStaticFile : ""}
            >
              {subTitle}
            </Typography>
          )}
        </Box>
        <Box sx={{ width: "100%" }}>
          <Box
            component={"label"}
            ref={labelRefHandler}
            className="drop-zone"
            onDrop={(e) => {
              e.preventDefault();
              handleFile(e.dataTransfer.files[0]);
            }}
            onDragOver={(e) => {
              e.preventDefault();
            }}
            sx={labelErrorSx({
              error,
              height: label?.offsetWidth,
              hidden: !!isThereStaticFile,
            })}
          >
            <Icon name="image-plus" className="image-plus" />
            <Box textAlign={"center"}>
              <Typography
                data-testid="title-choose"
                component={"span"}
                className="btn-txt"
              >
                {t("MODAL.CHOOSE_FILE")}
              </Typography>

              <Typography
                data-testid="title-deposite"
                component="span"
                sx={{
                  display: "none",
                  color: "secondary.main",
                  "@media (min-width: 768px)": { display: "initial" },
                }}
              >
                {`  `}
                {t("MODAL.DEPOSIT_FILE")}
              </Typography>
            </Box>
            <Stack sx={{ alignItems: "center" }} className="prerequisites">
              {children}
            </Stack>
            <input
              type="file"
              hidden
              accept={accept}
              onChange={inputChangeHandler}
            />
          </Box>
          {isThereStaticFile && fileItem && (
            <Box
              className={classes.boxFilename}
              sx={{ height: label?.offsetWidth }}
            >
              <Stack className={classes.svgBloc}>
                <Icon
                  name="file-attachment"
                  sx={{
                    color: "primary.main",
                    transition: "opacity 600ms ease-in-out",
                  }}
                />

                <Typography component="span" color="primary.main">
                  {fileItem?.name || ""}
                </Typography>
              </Stack>
            </Box>
          )}
          {error && (
            <Typography component="span" className="error">
              {t("BUSINESSES.EDIT_LOGO.ERROR_FILE")}
            </Typography>
          )}
        </Box>
        {/* <Box>
                    <Button
                        sx={{
                            border: "none",
                            outline: "none",
                            background: "none",
                            textDecoration: "underline",
                            color: "button.primary",
                        }}
                    >
                        {t("CAMPAIGN.DOWNLOAD_MODEL")}
                    </Button>
                </Box> */}
        <Stack
          className="action-wrapper"
          flexDirection={"row"}
          gap={2}
          sx={{
            flexDirection: "row!important",
          }}
        >
          <Button onClick={closeModal}>{t("FORMS.CANCEL")}</Button>
          <Button
            variant={"contained"}
            onClick={onAdd}
            sx={{ display: "flex", gap: 1, alignItems: "center" }}
            disabled={!isThereStaticFile}
          >
            <Icon name="file-plus" /> {t("COMMON.ADD")}
          </Button>
        </Stack>
      </Paper>
    </Modal>
  );
};

export default UploadFile;
