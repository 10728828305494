import { withSxProp } from "@/utils";
import { Button, IconButton, Popover } from "@mui/material";
import { ReactNode, useState } from "react";
import Icon from "../../Icon";

const PopoverButton = ({
  label,
  icon = <Icon name="three-dots-horizontal" />,
  children,
  buttonIcon,
  buttonSx,
  buttonEndIcon,
  sx = {},
}: {
  label?: string;
  icon?: ReactNode;
  buttonIcon?: ReactNode;
  buttonEndIcon?: ReactNode;
  buttonSx?: any;
  children: ReactNode;
  sx?: any;
}) => {
  const [containerEl, setContainerEl] = useState(null);
  const open = Boolean(containerEl);
  const id = open ? "simple-popover" : undefined;

  const handleOpen = (e) => {
    setContainerEl(e.currentTarget);
  };

  const handleClose = () => {
    setContainerEl(null);
  };

  return (
    <>
      {!label && (
        <IconButton aria-describedby={id} onClick={handleOpen}>
          {icon}
        </IconButton>
      )}
      {label && (
        <Button
          startIcon={buttonIcon}
          endIcon={buttonEndIcon}
          aria-describedby={id}
          variant="contained"
          data-testid="button-popover"
          onClick={handleOpen}
          sx={buttonSx}
          role="button"
        >
          {label}
        </Button>
      )}
      <Popover
        open={open}
        anchorEl={containerEl}
        onClose={handleClose}
        role="popover"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        elevation={6}
        sx={withSxProp(
          {
            padding: 8,
            "& .MuiPopover-paper": {
              borderRadius: label ? "8px" : "16px",
            },
            "& .MuiModal-backdrop": {
              background: "none",
            },
          },
          sx
        )}
      >
        {children}
      </Popover>
    </>
  );
};

export default PopoverButton;
