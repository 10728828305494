import { Box } from "@mui/material";
import { PropsWithChildren, useEffect } from "react";

import BaseAppBar from "@/components/base/app-bar";
import BaseMenu from "@/components/menu";
import useCreateMassAccount from "@/hooks/useCreateMassAccount";
import useWindowSize from "@/hooks/useWindowSize";
import UiStore from "@/store/ui";
import useStyles from "./styles";

const Layout = ({ children }: PropsWithChildren<any>) => {
  const { classes } = useStyles();
  const [width] = useWindowSize();
  const { setShowMenu } = UiStore();
  const { hideMenu } = useCreateMassAccount();

  useEffect(() => {
    setShowMenu(width >= 768);
  }, [width, setShowMenu]);

  return (
    <Box className={classes.layout}>
      {!hideMenu && <BaseMenu />}
      <Box className={`children-container ${hideMenu && "full-width"}`}>
        <BaseAppBar />
        <Box className="children-wrapper">{children}</Box>
      </Box>
    </Box>
  );
};

export default Layout;
