import useWindowSize from "@/hooks/useWindowSize"
import { useCallback, ChangeEvent, useState, useEffect } from "react"

const useSearchInputFilter = ({ setAnchorEl, setInputWidth, setCheckedLength, setWithSearch, withSearch }) => {
    useWindowSize()
    const [stackRef, setStackRef] = useState<HTMLDivElement>()

    const handleClick = useCallback(() => {
        setAnchorEl(stackRef)
    }, [setAnchorEl, stackRef])

    const handleClose = useCallback(() => {
        setAnchorEl(null)
    }, [setAnchorEl])

    const handleInputRef = useCallback(
        (ref: HTMLDivElement) => {
            setStackRef(ref)
            if (ref) {
                setInputWidth(ref.offsetWidth)
            }
        },
        [setInputWidth]
    )

    const refreshCheckedLength = useCallback(() => {
        setCheckedLength(withSearch?.checkboxList.filter((checkbox) => checkbox.checked).length || 0)
    }, [setCheckedLength, withSearch?.checkboxList])

    const handleCheckbox = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const newCheckboxList = withSearch.checkboxList.map((checkbox) => {
                if (checkbox.name === e.target.name) {
                    return { ...checkbox, checked: e.target.checked }
                }
                return checkbox
            })
            setWithSearch((prevWithSearch) => ({
                ...prevWithSearch,
                checkboxList: newCheckboxList,
            }))
        },
        [setCheckedLength, setWithSearch, withSearch.checkboxList]
    )

    const handleClearFilter = useCallback(() => {
        setWithSearch((current) => ({
            ...current,
            checkboxList: current.checkboxList.map((entry) => ({
                ...entry,
                checked: false,
            })),
        }))
        setCheckedLength(0)
    }, [setCheckedLength, setWithSearch])

    const handleClearTextSearch = useCallback(() => {
        setWithSearch((current) => ({
            ...current,
            textSearch: "",
        }))
    }, [setWithSearch])

    const handleInputText = useCallback(
        (event: any) => {
            setWithSearch((current) => ({
                ...current,
                textSearch: event.target.value || "",
            }))
        },
        [setWithSearch]
    )

    useEffect(() => {
        refreshCheckedLength()
    }, [refreshCheckedLength])

    return {
        handleClick,
        handleClose,
        handleInputRef,
        handleCheckbox,
        handleClearFilter,
        handleClearTextSearch,
        handleInputText,
    }
}

export default useSearchInputFilter
