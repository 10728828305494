import { useCallback, useEffect, useMemo, useState } from "react";
import { Column } from "../partials/custom-table";
import { useTranslation } from "react-i18next";
import { UserApi } from "@/api";
import UserSessionStore from "@/store/user-session";
import UiStore from "@/store/ui";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

type ColumnProps = Column & {
  id: "uid";
};

const useTableUsers = () => {
  const { t } = useTranslation();
  const { preferredLanguage } = UiStore();
  const [page, setPage] = useState(0);
  const rowsPerPage = 100;
  const [totalCount, setTotalCount] = useState(0);
  const [rows, setRows] = useState<any[]>([]);
  const { profile } = UserSessionStore();
  const navigate = useNavigate();

  const fetchListUsersAdmin = useCallback(() => {
    return UserApi.fetchListUserAdmin({
      page_number: page,
      page_size: rowsPerPage,
      user_uid: profile?.uid,
    });
  }, [profile, page, rowsPerPage]);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const columns: readonly ColumnProps[] = useMemo(() => {
    return [
      {
        id: "uid",
        label: t("COMMON.RESELLER"),
        component: ({ column, row }) => {
          return (
            <Box
              sx={{
                cursor: "pointer",
                "&:hover": {
                  "&::after": {
                    content: "''",
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0",
                    left: "0",
                    backgroundColor: "rgba(1, 128, 255, 0.04)",
                  },
                },
              }}
              onClick={() => navigate(`/users/edit/${row.uid}`)}
            >
              {row.firstname ?? ""} {row.lastname ?? ""}
            </Box>
          );
        },
      },
    ];
  }, [t, preferredLanguage]);

  useEffect(() => {
    (async () => {
      const response = await fetchListUsersAdmin();
      const populated =
        response?.data?.map((data) => ({
          ...data,
          created: data.created && dayjs(data.created, "YYYY-MM-DD").format("DD MMMM YYYY"),
        })) || [];
      setTotalCount(response?.metadata?.total_count || populated?.length);
      setRows(populated);
    })();
  }, [fetchListUsersAdmin]);

  return {
    columns,
    page,
    rowsPerPage,
    rows,
    totalCount,
    handleChangePage,
  };
};

export default useTableUsers;
