import { ResellerService } from "@/services"
import NotificationStore from "@/store/notification"
import ResellerStore from "@/store/reseller"
import { GoogleOAuthProvider } from "@react-oauth/google"
import { useEffect, useState } from "react"

const AsyncGoogleOAuthProvider = ({ children }) => {
    const { resellerFetched } = ResellerStore()
    const [googleIdentityClientId, setGoogleIdentityClientId] = useState(null)
    const { update: updateNotifState } = NotificationStore()

    useEffect(() => {
        if (!resellerFetched) {
            const getReseller = async () => {
                const { googleIdentityClientId: GOOGLE_IDENTITY_CLIENT_ID } = await ResellerService.getResellerInfos()
                setGoogleIdentityClientId(GOOGLE_IDENTITY_CLIENT_ID)
            }
            getReseller()
        }
        updateNotifState({ show: false })
    }, [resellerFetched, updateNotifState])

    if (!googleIdentityClientId) {
        return <></>
    }

    return <GoogleOAuthProvider clientId={googleIdentityClientId}>{children}</GoogleOAuthProvider>
}

export default AsyncGoogleOAuthProvider
