import logoDilypse from "@/assets/images/logo-dilypse.png";
import Icon from "@/components/base/Icon";
import BaseInput from "@/components/base/input";
import useStyles from "@/pages/login/styles";
import { UserSessionService } from "@/services";
import UiService from "@/services/ui";
import ResellerStore from "@/store/reseller";
import UiStore from "@/store/ui";
import UserSessionStore from "@/store/user-session";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ForgotPasswordPage = ({ handleSubmit }) => {
  const { classes } = useStyles();
  const [loading, setLoading] = useState(false);
  const [mail, setMail] = useState("");
  const [showMailAdornment, setShowMailAdornment] = useState(false);
  const [emailInvalid] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const { resellerLogo, resellerName } = ResellerStore();
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const { preferredLanguage } = UiStore();
  const { isConnected } = UserSessionStore();

  const handleResetPassword = async () => {
    setLoading(true);
    setError("");

    if (!isConnected) {
      setError(t("SYSTEM_ERROR.INVALID_REQUEST"));
    } else {
      const resetRequest = await UserSessionService.resetPassword(
        mail,
        preferredLanguage
      );
      if (resetRequest.error) {
        setError(t("SYSTEM_ERROR.INVALID_REQUEST"));
      } else {
        setMailSent(true);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    UiService.setState({ title: "PASSWORD.FORGOT.TITLE" });
    // UserSessionService.clearSession();
  }, []);

  return (
    <Box className={classes.loginWrapper}>
      {resellerLogo && (
        <img
          data-testid="logo"
          src={resellerLogo || logoDilypse}
          alt={resellerName || "Dilypse"}
        />
      )}

      <Box>
        <Typography variant="h1" fontSize={20} className="title-header">
          {t("PASSWORD.FORGOT.TITLE_HEADER")}
        </Typography>
        {!mailSent && (
          <Typography variant="subtitle1">
            {t("PASSWORD.FORGOT.SUBTITLE")}
          </Typography>
        )}
      </Box>
      {!mailSent && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (handleSubmit !== null) {
              handleSubmit(e);
            }
            handleResetPassword();
          }}
        >
          <BaseInput
            type="email"
            label={t("LABEL.EMAIL")}
            value={mail}
            data-testid="email"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setMail(e.target.value.trim())
            }
            onFocus={() => setShowMailAdornment(true)}
            onBlur={() => setShowMailAdornment(false)}
            startAdornment={
              (showMailAdornment || mail) && <Icon name="envelope" />
            }
          />
          {error && (
            <Typography component={"span"} className="error">
              {error}
            </Typography>
          )}
          <Button
            variant="contained"
            type="submit"
            data-testid="forgotButton"
            disabled={!mail || emailInvalid || loading}
            onClick={() => handleResetPassword()}
          >
            {loading && <CircularProgress size={16} />}
            {t("BUTTON.RESET_PASSWORD")}
          </Button>
        </form>
      )}
      {mailSent && (
        <>
          <Typography variant="body1" id="message-body1">
            {t("PASSWORD.FORGOT.MESSAGE_BODY_1")} <br /> {mail}. <br />
            {t("PASSWORD.FORGOT.MESSAGE_BODY_12")}
          </Typography>
          <Typography variant="body2" id="message-body2">
            {t("PASSWORD.FORGOT.MESSAGE_BODY_2")}{" "}
            <Typography
              component={"span"}
              onClick={() => {
                setMailSent(false);
                setMail("");
              }}
            >
              {t("BUTTON.RETRY")}.
            </Typography>
          </Typography>
        </>
      )}
    </Box>
  );
};

export default ForgotPasswordPage;
