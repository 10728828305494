import HeaderTitle from "@/components/base/header-title";
import TableUsers from "@/components/table/table-users";
import TopbarUsers from "@/components/topbar/topbar-users";
import useUiPageSession from "@/hooks/useUiPageSession";
import { Paper } from "@mui/material";
import { useTranslation } from "react-i18next";

const UsersPage = () => {
  const { t } = useTranslation();
  useUiPageSession({ title: "Users", page: "user-list" });

  return (
    <>
      <HeaderTitle icon="userSvg" title={t("MENU.USERS")} />
      <TopbarUsers />
      <Paper data-testid="table-users">
        <TableUsers />
      </Paper>
    </>
  );
};

export default UsersPage;
