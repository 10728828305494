export const PROVINCES_CA = [
  "AB",
  "BC",
  "MB",
  "NB",
  "NL",
  "NT",
  "NS",
  "NU",
  "ON",
  "PE",
  "QC",
  "SK",
  "YT",
];
export const PROVINCE = {
  // Provinces et territoires canadiens
  alberta: "AB",
  "colombie-britannique": "BC",
  "british-columbia": "BC",
  manitoba: "MB",
  "nouveau-brunswick": "NB",
  "new-brunswick": "NB",
  "terre-neuve-et-labrador": "NL",
  "newfoundland-and-labrador": "NL",
  "nouvelle-ecosse": "NS",
  "nova-scotia": "NS",
  "territoires-du-nord-ouest": "NT",
  "northwest-territories": "NT",
  nunavut: "NU",
  ontario: "ON",
  "ile-du-prince-edouard": "PE",
  "prince-edward-island": "PE",
  quebec: "QC",
  québec: "QC",
  saskatchewan: "SK",
  "territoire-du-yukon": "YT",
  "yukon-territory": "YT",

  // États américains
  alaska: "AK",
  alabama: "AL",
  arkansas: "AR",
  arizona: "AZ",
  californie: "CA",
  california: "CA",
  colorado: "CO",
  connecticut: "CT",
  delaware: "DE",
  floride: "FL",
  florida: "FL",
  georgie: "GA",
  georgia: "GA",
  hawaii: "HI",
  iowa: "IA",
  idaho: "ID",
  illinois: "IL",
  indiana: "IN",
  kansas: "KS",
  kentucky: "KY",
  louisiane: "LA",
  louisiana: "LA",
  massachusetts: "MA",
  maryland: "MD",
  maine: "ME",
  michigan: "MI",
  minnesota: "MN",
  missouri: "MO",
  mississippi: "MS",
  montana: "MT",
  "caroline-du-nord": "NC",
  "north-carolina": "NC",
  "dakota-du-nord": "ND",
  "north-dakota": "ND",
  nebraska: "NE",
  "new-hampshire": "NH",
  "new-jersey": "NJ",
  "nouveau-mexique": "NM",
  "new-mexico": "NM",
  nevada: "NV",
  "new-york": "NY",
  ohio: "OH",
  oklahoma: "OK",
  oregon: "OR",
  pennsylvanie: "PA",
  pennsylvania: "PA",
  "rhode-island": "RI",
  "caroline-du-sud": "SC",
  "south-carolina": "SC",
  "dakota-du-sud": "SD",
  "south-dakota": "SD",
  tennessee: "TN",
  texas: "TX",
  utah: "UT",
  virginie: "VA",
  virginia: "VA",
  vermont: "VT",
  washington: "WA",
  wisconsin: "WI",
  "virginie-occidentale": "WV",
  "west-virginia": "WV",
  wyoming: "WY",
  "district-de-columbia": "DC",
  "district-of-columbia": "DC",
};
