import { ApiService } from "../";


class AccountApi {

    checkAccessCompany = async ({ user_uid, company_id }: { user_uid: string, company_id: string }) => {
        return ApiService.get(`/accounts`, {
            user_uid,
            q: company_id
        });
    };
}

export default new AccountApi();
