import Icon from "@/components/base/Icon";
import CustomTooltip from "@/components/base/Tooltip";
import BaseSelect from "@/components/base/select";
import { PROVINCES_CA } from "@/data/provinces";
import { US_STATES_CODE } from "@/data/states";
import { useDebounce } from "@/hooks/useDebounce";
import useAccountsStore, { AccountType } from "@/store/accounts";
import {
  formatZipByCountry,
  isValidEmail,
  isZipValid,
  markDuplicates,
} from "@/utils";
import { paginate } from "@/utils/paginate";
import { Box, IconButton, Tooltip } from "@mui/material";
import { CountryCode, isValidNumberForRegion } from "libphonenumber-js";
import deburr from "lodash.deburr";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column } from "../partials/custom-table";

type ColumnProps = Column & {
  id:
    | "name"
    | "address"
    | "zip"
    | "city"
    | "province_code"
    | "country_code"
    | "business_phone"
    | "lang"
    | "user_firstname"
    | "user_lastname"
    | "user_email"
    | "action"
    | "status"
    | "price_name"
    | "business_uid"
    | "id";
};

type InputOrValueProps = {
  value: string;
  updateRow: (value: string) => void;
  type?: string;
  options?: { label?: string; value?: string }[];
  otherProps?: {
    country?: string;
  };
  disabled?: boolean;
};

const InputOrValue = ({
  value,
  updateRow,
  type = "text",
  options = [],
  otherProps,
  disabled = false,
}: InputOrValueProps) => {
  const [inputValue, setInputValue] = useState(value);
  const newInputValue = useDebounce(inputValue, 500);
  const countries = ["FR", "US", "CA"];

  const renderBaseSelect = (defaultValue, options, onItemClicked, disabled) => (
    <BaseSelect
      sx={{
        "& .MuiFilledInput-input": {
          paddingTop: "12px",
        },
        "&.Mui-disabled": {
          backgroundColor: "transparent",
          "&:before": {
            border: "none",
          },
        },
      }}
      defaultValue={defaultValue}
      options={options}
      onItemClicked={onItemClicked}
      disabled={disabled}
    />
  );

  useEffect(() => {
    updateRow(newInputValue);
  }, [newInputValue]);

  return (
    <Box
      sx={{
        "& .invalid-input": {
          border: "none",
        },
        "& .MuiFilledInput-underline": {
          backgroundColor: "transparent",
          border: "none",
        },
        "& .MuiSelect-select": {
          fontSize: "0.875rem",
        },
        "& input": {
          fontSize: "14px",
        },
      }}
    >
      {type !== "select-lang" &&
        type !== "select-country" &&
        type !== "select-province" && (
          <input
            type="text"
            value={inputValue}
            className="invalid-input"
            disabled={disabled}
            onChange={(e) => {
              const value = e.target.value;
              if (type === "zip") {
                const formattedZip = formatZipByCountry(
                  value,
                  otherProps?.country
                );
                if (formattedZip || formattedZip === "") {
                  setInputValue(formattedZip);
                }
              } else {
                setInputValue(value);
              }
            }}
          />
        )}
      {type === "select-lang" &&
        renderBaseSelect(
          inputValue?.toLowerCase(),
          options,
          (e) => setInputValue(e.value),
          disabled
        )}
      {type === "select-country" &&
        renderBaseSelect(
          !countries.includes(inputValue) ? "" : inputValue,
          options,
          (e) => setInputValue(e.value),
          disabled
        )}
      {type === "select-province" &&
        renderBaseSelect(
          inputValue,
          options,
          (e) => setInputValue(e.value),
          disabled
        )}
    </Box>
  );
};

const useTableUploadComptes = () => {
  const { newContacts, update: updateAccounts } = useAccountsStore();
  const [rows, setRows] = useState<AccountType[]>([]);
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const provinceList = useMemo(() => {
    return PROVINCES_CA.map((code) => ({
      label: t(`PROVINCE.${code}`),
      value: code,
    })).sort((a, b) => (deburr(a.label) > deburr(b.label) ? 1 : -1));
  }, [t]);

  const stateList = useMemo(() => {
    return US_STATES_CODE.map((code) => ({
      label: t(`PROVINCE.${code}`),
      value: code,
    })).sort((a, b) => (deburr(a.label) > deburr(b.label) ? 1 : -1));
  }, [t]);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const rowsLength = useMemo(() => rows?.length, [rows]);

  const updateIsValidForEelement = (company: AccountType) => {
    const account = company;
    //if the country is Canada or Us we should verify if the province code is set
    account["isValid"] =
      !!account?.price_name &&
      !!account?.name &&
      !!account?.address &&
      isZipValid(account?.zip, account?.country_code) &&
      !!account?.zip &&
      !!account?.city &&
      !!account?.country_code &&
      !!account?.business_phone &&
      isValidNumberForRegion(
        account?.business_phone,
        account?.country_code as CountryCode
      ) &&
      !!account?.user_lastname &&
      !!account?.user_firstname &&
      !!account?.user_email &&
      isValidEmail(account?.user_email) &&
      !!account?.lang;
    // If the country is not FR we should verify if the province code is set and exists
    if (account?.country_code != "FR") {
      if (
        account?.country_code === "US" &&
        !US_STATES_CODE.includes(account?.province_code)
      ) {
        account["isValid"] = false;
      } else if (
        account?.country_code === "CA" &&
        !PROVINCES_CA.includes(account?.province_code)
      )
        account["isValid"] = false;
    }

    return account;
  };

  const handleDelete = useCallback(
    (idToRemove) => {
      // remove the contact from the list of contacts
      const updatedContacts = newContacts.filter(
        (contact) => contact.id !== idToRemove
      );
      const updatedRows = rows.filter((contact) => contact.id !== idToRemove);

      setRows(
        markDuplicates(updatedRows).map((item) =>
          updateIsValidForEelement(item)
        )
      );

      updateAccounts({
        newContacts: markDuplicates(updatedContacts).map((item) =>
          updateIsValidForEelement(item)
        ),
      });
    },
    [newContacts, rows]
  );

  const updateOriginalObjects = useCallback(
    (invalidObjects = []) => {
      const originalObjects = rows;
      // Loop through the invalidObjects array
      for (const invalidObject of invalidObjects) {
        // Find the corresponding object in the originalObjects array
        const originalObject = originalObjects.find(
          (obj) => obj.id === invalidObject.id
        );

        // If the corresponding object is found, update its properties
        if (originalObject) {
          // Update the properties of the originalObject with the values from the invalidObject
          Object.assign(originalObject, invalidObject);
        }
      }

      const newContactList = markDuplicates(originalObjects).map((item) => {
        return updateIsValidForEelement(item);
      });
      if (newContactList?.length) {
        setRows(newContactList);
      }
    },
    [rows]
  );

  const columns: readonly ColumnProps[] = useMemo(() => {
    return [
      {
        id: "status",
        label: "",
        component: ({ column, row }) => {
          return (
            <>
              {(() => {
                if (row?.hasDuplicate) {
                  return (
                    <Tooltip
                      title={t("COMPANIES.DUPLICATE_TOOLTIP")}
                      placement="bottom"
                    >
                      <IconButton>
                        <Icon name="copy2" />
                      </IconButton>
                    </Tooltip>
                  );
                }

                if (!row?.isValid) {
                  return (
                    <CustomTooltip title={t("COMPANIES.INVALID_TOOLTIP")}>
                      <IconButton>
                        <Icon name="warning-grey" />
                      </IconButton>
                    </CustomTooltip>
                  );
                }

                if (row?.isValid) {
                  return (
                    <Icon
                      name="check"
                      sx={{
                        color: "success.main",
                      }}
                    />
                  );
                }

                return null;
              })()}
            </>
          );
        },
      },
      {
        id: "price_name",
        label: t("FORMS.LICENCE"),
        component: ({ column, row }) => {
          return (
            <Box className={!row[column?.id] && "isColumnInvalid"}>
              <InputOrValue
                value={row[column?.id]}
                updateRow={(input) => {
                  row[column?.id] = input;
                  updateOriginalObjects([row]);
                }}
              />
            </Box>
          );
        },
      },
      {
        id: "name",
        label: t("FORMS.COMPANY"),
        component: ({ column, row }) => {
          return (
            <Box
              className={
                (!row[column?.id] || row?.hasDuplicate) && "isColumnInvalid"
              }
            >
              <InputOrValue
                value={row[column?.id]}
                updateRow={(input) => {
                  row[column?.id] = input;
                  updateOriginalObjects([row]);
                }}
              />
            </Box>
          );
        },
      },
      {
        id: "address",
        label: t("FORMS.ADDRESS"),
        component: ({ column, row }) => {
          return (
            <Box className={!row[column?.id] && "isColumnInvalid"}>
              <InputOrValue
                value={row[column?.id]}
                updateRow={(input) => {
                  row[column?.id] = input;
                  updateOriginalObjects([row]);
                }}
              />
            </Box>
          );
        },
      },
      {
        id: "zip",
        label: t("FORMS.ZIP"),
        component: ({ column, row }) => {
          return (
            <Box
              className={
                (!isZipValid(row[column?.id], row?.country_code) ||
                  !row[column?.id]) &&
                "isColumnInvalid"
              }
            >
              <InputOrValue
                value={row[column?.id]}
                updateRow={(input) => {
                  row[column?.id] = input;
                  updateOriginalObjects([row]);
                }}
                type="zip"
                otherProps={{
                  country: row?.country_code,
                }}
              />
            </Box>
          );
        },
      },
      {
        id: "city",
        label: t("FORMS.TOWN"),
        component: ({ column, row }) => {
          return (
            <Box className={!row[column?.id] && "isColumnInvalid"}>
              <InputOrValue
                value={row[column?.id]}
                updateRow={(input) => {
                  row[column?.id] = input;
                  updateOriginalObjects([row]);
                }}
              />
            </Box>
          );
        },
      },
      {
        id: "province_code",
        label: t("FORMS.REGION"),
        component: ({ column, row }) => {
          const isNotValidRegion =
            !row[column?.id] ||
            (row?.country_code === "US" &&
              !US_STATES_CODE.includes(row[column?.id])) ||
            (row?.country_code === "CA" &&
              !PROVINCES_CA.includes(row[column?.id]));
          return (
            <Box
              className={
                row?.country_code != "FR"
                  ? isNotValidRegion && "isColumnInvalid"
                  : ""
              }
            >
              <InputOrValue
                value={row[column?.id]}
                updateRow={(input) => {
                  row[column?.id] = input;
                  updateOriginalObjects([row]);
                }}
                options={row?.country_code === "US" ? stateList : provinceList}
                type="select-province"
                disabled={row?.country_code === "FR" || row?.country_code === "BE"}
              />
            </Box>
          );
        },
      },
      {
        id: "country_code",
        label: t("FORMS.COUNTRY"),
        component: ({ column, row }) => {
          return (
            <Box className={!row[column?.id] && "isColumnInvalid"}>
              <InputOrValue
                value={row[column?.id]}
                updateRow={(input) => {
                  row[column?.id] = input;
                  updateOriginalObjects([row]);
                }}
                type="select-country"
                options={[
                  {
                    label: t("COUNTRY.FR"),
                    value: "FR",
                  },
                  {
                    label: t("COUNTRY.US"),
                    value: "US",
                  },
                  {
                    label: t("COUNTRY.CA"),
                    value: "CA",
                  },
                ]}
              />
            </Box>
          );
        },
      },
      {
        id: "business_phone",
        label: t("FORMS.BUSINESS_PHONE"),
        component: ({ column, row }) => {
          return (
            <Box
              className={
                (!row[column?.id] ||
                  !isValidNumberForRegion(
                    row[column?.id],
                    row?.country_code
                  )) &&
                "isColumnInvalid"
              }
            >
              <InputOrValue
                value={row[column?.id]}
                updateRow={(input) => {
                  row[column?.id] = input;
                  updateOriginalObjects([row]);
                }}
              />
            </Box>
          );
        },
      },
      {
        id: "user_firstname",
        label: t("FORMS.FIRSTNAME"),
        component: ({ column, row }) => {
          return (
            <Box className={!row[column?.id] && "isColumnInvalid"}>
              <InputOrValue
                value={row[column?.id]}
                updateRow={(input) => {
                  row[column?.id] = input;
                  updateOriginalObjects([row]);
                }}
              />
            </Box>
          );
        },
      },
      {
        id: "user_lastname",
        label: t("FORMS.LASTNAME"),
        component: ({ column, row }) => {
          return (
            <Box className={!row[column?.id] && "isColumnInvalid"}>
              <InputOrValue
                value={row[column?.id]}
                updateRow={(input) => {
                  row[column?.id] = input;
                  updateOriginalObjects([row]);
                }}
              />
            </Box>
          );
        },
      },
      {
        id: "user_email",
        label: t("FORMS.EMAIL"),
        component: ({ column, row }) => {
          return (
            <Box
              className={
                (!row[column?.id] ||
                  row[column?.id] === undefined ||
                  !isValidEmail(row[column?.id])) &&
                "isColumnInvalid"
              }
            >
              <InputOrValue
                value={row[column?.id]}
                updateRow={(input) => {
                  row[column?.id] = input;
                  updateOriginalObjects([row]);
                }}
              />
            </Box>
          );
        },
      },
      {
        id: "lang",
        label: t("FORMS.LANG"),
        component: ({ column, row }) => {
          return (
            <Box
              className={
                (!row[column?.id] || row[column?.id] === undefined) &&
                "isColumnInvalid"
              }
            >
              <InputOrValue
                value={row[column?.id]}
                updateRow={(input) => {
                  row[column?.id] = input;
                  updateOriginalObjects([row]);
                }}
                type="select-lang"
                options={[
                  {
                    label: "FR",
                    value: "fr-ca",
                  },
                  {
                    label: "EN",
                    value: "en",
                  },
                ]}
              />
            </Box>
          );
        },
      },
      {
        id: "id",
        label: "",
        component: ({ column, row, rowIndex }) => {
          return (
            <IconButton
              sx={{
                color: "#F46259",
              }}
              onClick={() => handleDelete(row?.id)}
              className="sticky-action-button"
            >
              <Icon name="trash" />
            </IconButton>
          );
        },
      },
    ];
  }, [t, rows]);

  const handleSubmit = useCallback(async () => {
    let finalObjects = newContacts;
    // Loop through the invalidObjects array
    for (const invalidObject of rows) {
      // Find the corresponding object in the originalObjects array
      const originalObject = finalObjects.find(
        (obj) => obj.id === invalidObject.id
      );

      // If the corresponding object is found, update its properties
      if (originalObject) {
        // Update the properties of the originalObject with the values from the invalidObject
        Object.assign(originalObject, invalidObject);
      }
    }

    updateAccounts({
      sendContacts: true,
      newContacts: finalObjects,
    });
  }, [rows, newContacts]);

  const filteredData = useMemo(() => {
    // create a custom pagination based on the rowsPerPage and page
    const response = paginate(rows, page === 0 ? 1 : page + 1, rowsPerPage);
    return response.items || [];
  }, [rows, page]);

  useEffect(() => {
    if (newContacts?.length && !rows?.length) {
      // Always reset the current page value
      setPage(0);
      const invalids = [...newContacts].filter(
        (item) => item.hasDuplicate || !item.isValid
      );
      setRows(invalids);
    }
  }, [newContacts]);

  useEffect(() => {
    //If the current page is empty, go to the previous page
    if (filteredData?.length === 0 && page - 1 >= 0) {
      setPage(page - 1);
    }
  }, [filteredData, page]);

  return {
    rows,
    columns,
    rowsPerPage,
    totalCount: rowsLength,
    page,
    filteredData,
    handleChangePage,
    handleSubmit,
    updateAccounts,
    updateOriginalObjects,
    handleDelete,
  };
};

export default useTableUploadComptes;
