import { ResellerApi } from "@/api";
import { ResellerType } from "@/api/reseller";
import { InfoType } from "@/components/base/input-phone";
import useFormater from "@/hooks/useFormater";
import { ResellerService } from "@/services";
import ResellerStore from "@/store/reseller";
import UiStore from "@/store/ui";
import {
  checkZipIsValid,
  formatZipByCountry,
  isPhoneValid,
  isValidEmail,
  isValidURL,
} from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { CountryCode } from "libphonenumber-js";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";

const useResellerForm = () => {
  const { t } = useTranslation();
  const { resellerId } = useParams();
  const [defaultCountry, setDefaultCountry] = useState<CountryCode>("CA");
  const { conflictedFields, updateState } = ResellerStore();
  const navigate = useNavigate();
  const { preferredLanguage } = UiStore();
  const { internationalPhone } = useFormater();

  const defaultValue = useMemo((): ResellerType => {
    return {
      name: "",
      cgv_url: "",
      user_email: "",
      user_firstname: "",
      user_lastname: "",
      lang: preferredLanguage !== "en" ? "fr-CA" : preferredLanguage,
      business_phone: "",
      country_code: "CA",
      address: "",
      city: "",
      zip: "",
      province_code: "",
      logo: null,
      logo_dark: null,
      favicon: null,
      url_support: "",
      email_support: "",
      phone_support: "",
      license_limit: 1,
      url_white_label: "",
      email_spotconnect: "", //email sender
      sms_sender: "",
      showUploadLogoWhite: false,
      showUploadLogoDark: false,
      showUploadFavicon: false,
      unlimited: false,
      currency_code: "eur",
      payment_type: 1,
    };
  }, [preferredLanguage]);

  const schema = useMemo(() => {
    return z.object({
      name: z.string().trim().min(1, t("FORMS.ERROR.FIELD_REQUIRED")),
      cgv_url: z.string().optional(),
      user_firstname: z.string().trim().min(1, t("FORMS.ERROR.FIELD_REQUIRED")),
      user_lastname: z.string().trim().min(1, t("FORMS.ERROR.FIELD_REQUIRED")),
      user_email: z
        .string()
        .min(1, t("FORMS.ERROR.FIELD_REQUIRED"))
        .refine((value) => isValidEmail(value), {
          message: t("FORMS.ERROR.INVALID_FORMAT_EMAIL"),
        }),
      country_code: z.string().min(1, t("FORMS.ERROR.FIELD_REQUIRED")),
      address: z.string().trim().min(1, t("FORMS.ERROR.FIELD_REQUIRED")),
      city: z.string().trim().min(1, t("FORMS.ERROR.FIELD_REQUIRED")),
      zip: z.string().min(1, t("FORMS.ERROR.FIELD_REQUIRED")),
      business_phone: z
        .string()
        .optional()
        .refine((value) => isPhoneValid(value), {
          message: t("FORMS.ERROR.PHONE_INVALID"),
        }),
      province_code: z.string().optional(),
      logo: z
        .string()
        .min(1, t("FORMS.ERROR.FIELD_REQUIRED"))
        .or(
          z.instanceof(File, {
            message: t("FORMS.ERROR.FIELD_REQUIRED"),
          })
        ),
      logo_dark: z
        .string()
        .min(1, t("FORMS.ERROR.FIELD_REQUIRED"))
        .or(
          z.instanceof(File, {
            message: t("FORMS.ERROR.FIELD_REQUIRED"),
          })
        ),
      favicon: z
        .string()
        .min(1, t("FORMS.ERROR.FIELD_REQUIRED"))
        .or(
          z.instanceof(File, {
            message: t("FORMS.ERROR.FIELD_REQUIRED"),
          })
        ),
      currency_code: z.string().min(1, t("FORMS.ERROR.FIELD_REQUIRED")),
      payment_type: z.number().min(1, t("FORMS.ERROR.FIELD_REQUIRED")),
      license_limit: z.number().min(1, t("FORMS.ERROR.FIELD_REQUIRED")),
      url_support: z.string().optional(),
      email_support: z.string().optional(),
      phone_support: z
        .string()
        .optional()
        .refine((value) => isPhoneValid(value), {
          message: t("FORMS.ERROR.PHONE_INVALID"),
        }),
      url_white_label: z
        .string()
        .min(1, t("FORMS.ERROR.FIELD_REQUIRED"))
        .refine((value) => isValidURL(value), {
          message: t("FORMS.ERROR.INVALID_URL_FORMAT"),
        }),
      email_spotconnect: z.string().optional(),
      sms_sender: z.string().max(10, t("FORMS.ERROR.MAX_LENGTH_11")).optional(),
      unlimited: z.boolean().optional().default(false),
    });
  }, [t]);

  const {
    control,
    formState,
    watch,
    setValue,
    trigger,
    setError,
    handleSubmit,
  } = useForm<ResellerType>({
    defaultValues: defaultValue,
    resolver: zodResolver(schema),
    mode: "onChange",
  });

  const formValue = useWatch({ control });

  const langOfInputPhone = useMemo(() => {
    return preferredLanguage?.toLowerCase() || "fr-ca";
  }, [preferredLanguage]);

  const isZipRequired = (value: string) => {
    return ((value || value === "") && !value) || value?.length < 1;
  };

  const isZipValid = (value: string, countryCode = formValue?.country_code) => {
    return (value || value === "") && checkZipIsValid(value, countryCode);
  };

  const validateZip = useCallback((value: string, countryCode: CountryCode) => {
    const formattedZip = formatZipByCountry(value, countryCode);

    if (formattedZip || formattedZip === "") {
      setValue("zip", formattedZip);
      if (isZipRequired(formattedZip)) {
        setError("zip", {
          type: "required",
          message: t("FORMS.ERROR.FIELD_REQUIRED"),
        });
      } else if (!isZipValid(formattedZip, countryCode)) {
        setError("zip", {
          type: "validate",
          message: t("FORMS.ERROR.ZIP_INVALID"),
        });
      } else {
        trigger(["zip"]);
      }
    }
  }, []);

  const handleAddressChange = useCallback(
    (data: any) => {
      setValue("address", data.address);
      setValue("city", data.city);
      trigger(["address", "city"]);
      if (data?.country !== "FR" && data?.country !== "BE") {
        setValue("province_code", data.province_code);
        trigger(["province_code"]);
      }
      validateZip(data?.zip, data?.country);
    },
    [trigger, setValue, validateZip]
  );

  const handleAddressInputValue = useCallback((address: string) => {
    setValue("address", address);
    trigger(["address"]);
  }, []);

  const handleCompanyPhone = useCallback(
    (info: InfoType, value: string) => {
      setValue("business_phone", value);
      trigger(["business_phone"]);
    },
    [setValue]
  );

  const handleSupportPhone = useCallback(
    (info: InfoType, value: string) => {
      setValue("phone_support", value);
      trigger(["phone_support"]);
    },
    [setValue]
  );

  const handleUnlimited = useCallback(
    (value: boolean) => {
      if (value) {
        setValue("unlimited", !formValue?.unlimited);
        trigger(["unlimited"]);
      }
    },
    [formValue]
  );

  const handleLimit = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const limit = e.target.value === "" ? 0 : +e.target.value;
    setValue("license_limit", limit);
    if (limit < 0) {
      setError("license_limit", {
        type: "manual",
        message: t("FORMS.ERROR.FIELD_REQUIRED"),
      });
    }
    trigger(["license_limit"]);
  }, []);

  const zipInputHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      validateZip(e.target.value, formValue?.country_code);
    },
    [validateZip, formValue?.country_code]
  );

  const handleLogoWhite = useCallback(
    (logo) => {
      setValue("logo", logo);
      trigger(["logo"]);
    },
    [setValue]
  );

  const handleLogoDark = useCallback(
    (logo) => {
      setValue("logo_dark", logo);
      trigger(["logo_dark"]);
    },
    [setValue]
  );

  const handleFavicon = useCallback(
    (logo) => {
      setValue("favicon", logo);
      trigger(["favicon"]);
    },
    [setValue]
  );

  const handleLogoOnClose = useCallback((type: string) => {
    switch (type) {
      case "logo":
        setValue("showUploadLogoWhite", false);
        break;

      case "logo_dark":
        setValue("showUploadLogoDark", false);
        break;

      case "favicon":
        setValue("showUploadFavicon", false);
    }
  }, []);

  const provinceCodeHandler = useCallback(
    (province: any) => {
      setValue("province_code", province?.value);
      if (
        formValue?.country_code !== "FR" &&
        formValue?.country_code !== "BE"
      ) {
        if (!province?.value) {
          setError("province_code", {
            type: "manual",
            message: t("FORMS.ERROR.FIELD_REQUIRED"),
          });
        }
      }
      trigger(["province_code"]);
    },
    [formValue, setValue]
  );

  const resetTriggerAddress = useCallback(() => {
    setValue("city", "");
    setValue("zip", "");
    setValue("address", "");
    setValue("province_code", "");

    trigger(["city", "address"]);
    if (formValue?.country_code !== "FR" && formValue?.country_code !== "BE") {
      setError("province_code", {
        type: "manual",
        message: t("FORMS.ERROR.FIELD_REQUIRED"),
      });
    }
    setError("zip", {
      type: "manual",
      message: t("FORMS.ERROR.FIELD_REQUIRED"),
    });
  }, [formValue]);

  const updateValues = useCallback((company) => {
    setValue("user_email", company?.user_email ?? "");
    setValue("user_firstname", company?.user_firstname ?? "");
    setValue("user_lastname", company?.user_lastname ?? "");
    setValue("uid", company?.uid ?? "");
    setValue("cgv_url", company?.cgv_url ?? "");
    setValue("lang", company?.lang ?? "");
    setValue("uid", company?.uid ?? "");
    setValue(
      "business_phone",
      company?.business_phone
        ? internationalPhone(
            company?.business_phone,
            company?.country_code ?? "CA",
            true
          )
        : ""
    );
    setValue("logo", company?.logo ?? "");
    setValue("logo_dark", company?.logo_dark ?? "");
    setValue("favicon", company?.favicon ?? "");
    setValue("url_support", company?.url_support ?? "");
    setValue("email_support", company?.email_support ?? "");
    setValue(
      "phone_support",
      company?.phone_support
        ? internationalPhone(
            company?.phone_support,
            company?.country_code ?? "CA",
            true
          )
        : ""
    );
    setValue(
      "license_limit",
      company?.license_limit === -1 ? 1 : company?.license_limit
    );
    setValue("url_white_label", company?.url_white_label ?? "");
    setValue("email_spotconnect", company?.email_spotconnect ?? "");
    setValue("sms_sender", company?.sms_sender ?? "");
    setValue("unlimited", company?.license_limit === -1);
    setValue("payment_type", company?.payment_type ?? 1);
    setValue("currency_code", company?.currency_code ?? "");
    setValue("country_code", company?.country_code ?? "");

    setValue("name", company?.name ?? "");
  }, []);

  const updateAddress = useCallback((company) => {
    setTimeout(() => {
      setValue("zip", company?.zip ?? "");
      setValue("city", company?.city ?? "");
      setValue("address", company?.address ?? "");
      if (company?.country_code !== "FR" && company?.country_code !== "BE") {
        setValue("province_code", company?.province_code ?? "");
      }
      trigger();
    }, 200);
  }, []);

  const onSubmit = useCallback(async () => {
    await ResellerService.onSubmit(
      formValue as ResellerType,
      navigate,
      resellerId
    );
  }, [resellerId, formValue]);

  useEffect(() => {
    if (defaultCountry !== formValue?.country_code) {
      resetTriggerAddress();
      setDefaultCountry(formValue?.country_code);
    }
  }, [formValue?.country_code]);

  useEffect(() => {
    if (conflictedFields?.length) {
      if (conflictedFields.includes("user_email")) {
        setError("user_email", {
          type: "manual",
          message: t("FORMS.ERROR.SAME_EMAIL"),
        });
      }
      if (conflictedFields.includes("name")) {
        setError("name", {
          type: "manual",
          message: t("RESELLERS.EXIST"),
        });
      }
      if (conflictedFields.includes("url_white_label")) {
        setError("url_white_label", {
          type: "manual",
          message: t("FORMS.ERROR.PRODUCT_URL_ALREADY_EXIST"),
        });
      }
    }
  }, [conflictedFields]);

  useEffect(() => {
    if (resellerId) {
      const fetchReseller = async () => {
        updateState({
          isLoading: true,
        });
        const data = await ResellerApi.getReseller(resellerId);

        if (data) {
          updateValues(data);
          updateAddress(data);
          updateState({
            conflictedFields: [],
            isLoading: false,
            resellerInfos: data,
          });
        }
      };
      fetchReseller();
    }
  }, [resellerId]);

  useEffect(() => {
    return () => {
      updateState({
        conflictedFields: [],
        resellerInfos: null,
        isLoading: false,
      });
    };
  }, []);

  return {
    control,
    formValue,
    formState,
    langOfInputPhone,
    onSubmit,
    trigger,
    watch,
    setValue,
    handleAddressChange,
    handleAddressInputValue,
    handleCompanyPhone,
    handleSupportPhone,
    zipInputHandler,
    handleLogoDark,
    handleLogoWhite,
    handleFavicon,
    provinceCodeHandler,
    handleUnlimited,
    handleLimit,
    handleLogoOnClose,
    handleSubmit,
  };
};

export default useResellerForm;
