import { AccountApi } from "@/api";
import UserSessionStore from "@/store/user-session";
import { useCallback, useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const RedirectPage = () => {
  const [params] = useSearchParams();
  const { profile, token } = UserSessionStore();
  const navigate = useNavigate();

  const redirectTo = useMemo(() => {
    const front_base_url = params.get("front_base_url");
    const company_uid = params.get("company_uid");
    return `/redirect?company_uid=${company_uid}&front_base_url=${front_base_url}`;
  }, []);

  const processRedirection = useCallback(async () => {
    if (!profile?.uid) {
      throw new Error(`Not Authorized. Session not found`);
    }

    const front_base_url = params.get("front_base_url");

    const company_uid = params.get("company_uid");
    const user_uid = profile.uid;
    const checkUserAccessCompany = await AccountApi.checkAccessCompany({
      company_id: company_uid,
      user_uid,
    });
    if (checkUserAccessCompany?.data?.length > 0) {
      window.open(
        `${front_base_url}/?user_uid=${user_uid}&company_uid=${company_uid}&token=${token}`,
        "_self"
      );
    } else {
      throw new Error(`Not Authorized to redirect to ${front_base_url}`);
    }
  }, [profile?.uid, params, token]);

  useEffect(() => {
    processRedirection().catch(() => {
      navigate(`/login?redirectTo=${encodeURIComponent(redirectTo)}`);
    });
  }, [redirectTo, processRedirection]);

  return <>Redirecting...</>;
};

export default RedirectPage;
