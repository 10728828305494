import Icon from "@/components/base/Icon";
import {
  Button,
  CircularProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { ReactNode } from "react";

interface PaperBlockProps {
  title: string;
  children?: ReactNode;
  titleVariant?: Variant;
  paperClassNames?: string;
  paperElevation?: number;
  paperFullHeight?: boolean;
  buttonText?: string;
  buttonLoading?: boolean;
  buttonOnClick?: () => void;
}

const PaperBlock = ({
  title,
  children,
  titleVariant = "h2",
  paperClassNames = "",
  paperElevation = 2,
  paperFullHeight = false,
  buttonText = "",
  buttonLoading = false,
  buttonOnClick,
}: PaperBlockProps) => {
  return (
    <Paper
      elevation={paperElevation}
      sx={{
        width: "100%",
        ...(paperFullHeight ? { height: "100%" } : {}),
      }}
      className={paperClassNames}
    >
      {buttonText && (
        <Stack flexDirection="row" justifyContent="space-between">
          <Typography variant={titleVariant} className="title">
            {title}
          </Typography>
          <Button
            variant={"contained"}
            data-testid="create"
            data-e2e="submit"
            onClick={buttonOnClick}
            disabled={buttonLoading}
          >
            {buttonLoading && (
              <CircularProgress data-testid="loading" size={16} sx={{ marginRight: "8px" }} />
            )}
            {!buttonLoading && (
              <Icon name={"send"} sx={{ marginRight: "8px" }} />
            )}
            {buttonText}
          </Button>
        </Stack>
      )}
      {!buttonText && (
        <Typography variant={titleVariant} className="title">
          {title}
        </Typography>
      )}
      {children}
    </Paper>
  );
};

export default PaperBlock;
