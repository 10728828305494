import useAccountsStore from "@/store/accounts";
import { useEffect, useMemo } from "react";

const useCreateMassAccount = () => {
  const { newContacts, sendContacts } = useAccountsStore();

  const hideMenu = useMemo(() => {
    return (
      (!sendContacts &&
        newContacts?.filter((item) => !item.isValid || item.hasDuplicate)
          .length !== 0) ||
      (!sendContacts &&
        newContacts?.length !== 0 &&
        newContacts?.filter((item) => !item.isValid && item.hasDuplicate)
          ?.length === 0) ||
      sendContacts
    );
  }, [sendContacts, newContacts]);

  // Prevent user from going back if we are currently creating mass companies
  useEffect(() => {
    if (hideMenu) {
      document.body.style.overscrollBehaviorX = "none";
    } else {
      document.body.style.overscrollBehaviorX = "auto";
    }

    return () => {
      // Reset the style when the component unmounts or when isTaskComplete changes
      document.body.style.overscrollBehaviorX = "auto";
    };
  }, [hideMenu]);

  return {
    hideMenu,
  };
};

export default useCreateMassAccount;
