import UiStore from "@/store/ui";
import {
  CountryCode,
  isValidNumberForRegion,
  parsePhoneNumber,
} from "libphonenumber-js";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

interface AddressParser {
  region?: string;
  countryCode?: CountryCode;
  address?: string;
  city?: string;
  zip?: string;
}

const useFormater = () => {
  const { t, i18n } = useTranslation();
  const { preferredLanguage } = UiStore();

  const parseAddress = ({
    region,
    countryCode,
    address,
    city,
    zip,
  }: AddressParser): string => {
    let fullAddress = "";
    if (countryCode === "FR" || countryCode === "BE") {
      fullAddress = `${address ? address + ", " : ""}${zip ? zip + " " : ""}${
        city ? city + ", " : ""
      }${region ? t(`PROVINCE.${region}`) + ", " : ""}${
        countryCode ? t(`COUNTRY.${countryCode}`) : ""
      }`;
    } else if (countryCode === "CA" || countryCode === "US") {
      fullAddress = `${address ? address + ", " : ""}${
        city ? city + ", " : ""
      }${region ? t(`PROVINCE.${region}`) + (zip ? " " : ", ") : ""}${
        zip ? zip + ", " : ""
      }${countryCode ? t(`COUNTRY.${countryCode}`) : ""}`;
    }
    return fullAddress.trim().replace(/^, */, "").replace(/, *$/, "");
  };

  const internationalPhone = (
    phone: string,
    countryCode: CountryCode = "CA",
    addPrefix = true
  ): string => {
    try {
      const formated = parsePhoneNumber(phone, countryCode);
      const prefix = addPrefix ? `+${formated.countryCallingCode} ` : "";
      if (formated.number) {
        if (countryCode === "CA" || countryCode === "US") {
          const formatedPhone = formated.nationalNumber
            .toString()
            .replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3"); // les numéros de téléphones US et CA ont des - à la place des espaces après le préfix
          return `${prefix}${formatedPhone}`;
        }
        return !prefix
          ? formated.nationalNumber
          : formated.formatInternational();
      }
      return "";
    } catch (error) {
      return "";
    }
  };

  /**
   * @param {string} url - L'url à nettoyer
   * @returns {string} L'url néttoyer
   * **/
  const cleanUrl = (url: string): string => {
    return url.replace(/^(https?:\/\/)?/i, "").replace(/\/$/, "");
  };

  const translateString = useCallback(
    (text: string, lang: string) => {
      return i18n.t(text, { lng: lang });
    },
    [preferredLanguage, i18n]
  );

  return {
    parseAddress,
    internationalPhone,
    cleanUrl,
    isValidPhone: isValidNumberForRegion,
    translateString,
  };
};

export default useFormater;
