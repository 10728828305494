import { CookiesService } from "@/services";
import UserSessionStore from "@/store/user-session";
import { decode64AndParse } from "@/utils";
import { useMemo } from "react";

const useProfileInfo = () => {
  const { profile, role } = UserSessionStore();
  const isAdminCompany = useMemo(() => {
    if (profile?.uid && role) {
      const roleList: any = decode64AndParse(role);
      if (
        roleList &&
        (roleList.includes("ROLE_ADMIN_LOCATION") ||
          roleList.includes("ROLE_ADMIN_ESTABLISHMENT"))
      ) {
        return false;
      }
    }
    return true;
  }, [profile?.uid, role]);

  const isSuperAdmin = useMemo(() => {
    const { is_super_admin } = CookiesService.getAll()
    return profile?.is_super_admin || (is_super_admin ?? false)
  }, [profile?.is_super_admin]);

  const isSwitchAccount = useMemo(() => {
    const { is_switch_account } = CookiesService.getAll()
    return profile?.is_switch_account || (is_switch_account ?? false)
  }, [profile?.is_switch_account]);

  return {
    isAdminCompany,
    isSuperAdmin,
    isSwitchAccount,
  };
};

export default useProfileInfo;
