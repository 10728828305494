import { SxProps, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const modalStyle: SxProps<Theme> = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "calc(100% - 48px)",
  width: 494,
  bgcolor: "background.paper",
  p: 2,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 2,
  "@media (max-width: 768px)": {
    // maxWidth: 376,
    width: "calc(100% - 5%)",
    padding: "16px",
  },
  "& .title-wrapper": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 1,
    "& h2": {
      fontSize: 24,
      fontWeight: 500,
      textAlign: "center",
    },
    "& p": {
      color: "action.active",
      textAlign: "center",
    },
    "& button": {
      position: "absolute",
      top: 8,
      right: 8,
      color: "primary.main",
    },
  },
  "& .action-wrapper": {
    display: "flex",
    gap: 1,
    flexDirection: "column",
    width: "100%",
    "& button": {
      padding: "8px 22px",
      fontSize: 15,
      fontWeight: 500,
      width: "100%",
      "&:first-of-type": {
        backgroundColor: "rgba(1, 128, 255, 0.12)",
        color: "primary.main",
        "&:disabled": {
          backgroundColor: "rgba(104, 115, 141, 0.32)",
        },
      },
    },
    "@media (min-width: 768px)": {
      flexDirection: "row",
      gap: 2,
    },
  },
};

const useStyles = makeStyles()(() => ({
  usersWrapper: {},
}));

export const labelErrorSx = ({
  error = false,
  height,
  hidden,
}: {
  error: boolean;
  height?: number;
  hidden: boolean;
}): SxProps => ({
  borderWidth: 2,
  borderColor: "transparent",
  borderStyle: "solid",
  ...(error && {
    borderColor: "error.main",
  }),
  height,
  cursor: "pointer",
  display: hidden ? "none" : "flex",
  "@media (min-width: 768px)": {
    height: height ? 346 : "auto",
  },
  "&:hover": {
    borderColor: error ? "error.main" : "divider",
    "& .prerequisites": {
      "& span": {
        color: "text.primary",
        transition: "color .6s ease-in-out",
      },
    },
    transition: "border-width .6s ease-in-out",
  },
});

export const logoSx: SxProps<Theme> = {
  padding: 2,
  width: "calc(100% - 16px)",
  maxWidth: 346 + 32,
  boxSizing: "border-box",
  "@media (min-width: 768px)": {
    width: "100%",
    p: 2,
  },
  "& .title-wrapper": {
    width: "100%",
    gap: 0,
    "& h2": {
      textAlign: "end",
      whiteSpace: "nowrap",
    },
    "& .btn-x": {
      position: "absolute",
      ml: "auto",
    },
  },
  "& .add-file": {
    display: "flex",
    gap: 1,
  },
  "& .error": {
    p: "3px 0px 0px 8px",
    fontSize: 12,
  },
  "& .drop-zone-two": {
    alignItems: "center",
    justifyContent: "center",
    padding: 2,
    flexDirection: "column",
    display: "flex",
    backgroundColor: "background.default",
    borderRadius: 1,
    maxWidth: 346,
    boxSizing: "border-box",
    gap: 1,
    color: "primary.main",
    "& .add-or-drop": {
      display: "flex",
      gap: 1,
      p: 1,
      "& span": {
        textAlign: "center",
        wordBreak: "break-word",
      },
    },
    "& .image-type": {
      fontSize: 14,
      color: "action.active",
      letterSpacing: "0.17px",
    },
    "& .btn-txt": {
      color: "primary.main",
      textDecoration: "underline",
    },
    "& .image-plus": {
      p: 1.5,
    },
  },
  "& .drop-zone": {
    alignItems: "center",
    justifyContent: "center",
    padding: 2,
    flexDirection: "column",
    backgroundColor: "background.default",
    borderRadius: 1,
    maxWidth: 346,
    height: 225,
    boxSizing: "border-box",
    gap: 1,
    color: "primary.main",
    "& .add-or-drop": {
      display: "flex",
      gap: 1,
      p: 1,
      "& span": {
        textAlign: "center",
        wordBreak: "break-word",
      },
    },
    "& .image-type": {
      fontSize: 12,
      color: "action.active",
    },
    "& .btn-txt": {
      color: "primary.main",
      textDecoration: "underline",
    },
    "& .image-plus": {
      p: 1.5,
    },
  },
  "& .action-wrapper": {
    "@media (min-width: 768px)": {
      flexDirection: "column",
    },
  },
};

export default useStyles;
export { modalStyle };
