import useProfileInfo from "@/hooks/useProfileInfo";
import { CookiesService } from "@/services";
import UserSessionStore from "@/store/user-session";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useRedirect = () => {
  const { isLoggedIn } = UserSessionStore();
  const navigate = useNavigate();
  const { isSuperAdmin } = useProfileInfo();

  useEffect(() => {
    // Récupérer l'URL actuelle
    const currentURL = window.location.pathname;
    const isBasePath = window.location.pathname === "/";
    const isAdminRoute = ["/resellers"].some((route) =>
      currentURL.startsWith(route)
    );


    if (isLoggedIn) {
        if (window.location.pathname?.toLowerCase().includes("/accounts/edit")) {
            CookiesService.set({ redirectLink: window.location.pathname });
        }
        if (isBasePath || (isAdminRoute && !isSuperAdmin)) {
            navigate("/accounts");
            return
        }
        return
    } else {
        navigate("/login");
        return
    }

  }, [isLoggedIn, isSuperAdmin, navigate]);
};

export default useRedirect;
