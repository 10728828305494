import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  boxFilename: {
    height: 225,
    color: "common.white",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      "&::before": {
        opacity: 1,
      },
      "& svg": {
        opacity: 1,
      },
    },
  },
  svgBloc: {
    gap: 16,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "rgba(1, 128, 255, 0.16)",
    borderRadius: 8,
    padding: "0px 16px",
    color: theme.palette.primary.main,
    margin: "auto",
  },
  hasStaticFile: {
    "& button": {
      display: "none",
    },
  },
}));

export default useStyles;
