import Icon from "@/components/base/Icon";
import PopoverButton from "@/components/base/buttons/popover-button";
import UploadFile from "@/components/base/upload-file/UploadFile";
import SearchInputFilter from "@/components/search-input-filter";
import { LANG_MAPPING, PHONE_DATA_MAPPING } from "@/data/phoneCountries";
import { PROVINCE, PROVINCES_CA } from "@/data/provinces";
import { US_STATES_CODE } from "@/data/states";
import { useDebounce } from "@/hooks/useDebounce";
import useFormater from "@/hooks/useFormater";
import useProfileInfo from "@/hooks/useProfileInfo";
import useAccountsStore, { AccountType } from "@/store/accounts";
import UiStore from "@/store/ui";
import { isValidEmail, isZipValid, markDuplicates } from "@/utils";
import { Add } from "@mui/icons-material";
import {
  Alert,
  Button,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { isValidNumberForRegion } from "libphonenumber-js";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import slugify from "slugify";
import { read, utils } from "xlsx";

export type FilterCompanyValue = {
  status: string[];
  text: string;
};

const TopbarComptes = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isSuperAdmin } = useProfileInfo();
  const [openUpload, setOpenUpload] = useState(false);
  const [hasFile, setHasFile] = useState(false);
  const {
    update: updateAccounts,
    count_by_status,
    filter,
    currentPage,
  } = useAccountsStore();
  const { internationalPhone } = useFormater();
  const { preferredLanguage } = UiStore();
  const [displayWarning, setDisplayWarning] = useState<boolean>(true);
  const [withSearch, setWithSearch] = useState({
    inputLabel: "COMMON.SEARCH",
    popoverTitle: "COMMON.STATUT",
    filterText: "BUSINESSES.FILTER_TOOLTIP_NO_SELECT",
    checkboxList: [
      {
        label: "STATUS.EMPTY_COMPANY",
        name: "empty_company",
        checked: filter?.status?.split(",")?.includes("EMPTY_COMPANY"),
        count: 0,
      },
      {
        label: "STATUS.CONNECTED",
        name: "connected",
        checked: filter?.status?.split(",")?.includes("CONNECTED"),
        count: 0,
      },
      {
        label: "STATUS.DUPLICATED",
        name: "duplicated",
        checked: filter?.status?.split(",")?.includes("DUPLICATED"),
        count: 0,
      },
      {
        label: "STATUS.DISCONNECTED",
        name: "disconnected",
        checked: filter?.status?.split(",")?.includes("DISCONNECTED"),
        count: 0,
      },
      {
        label: "STATUS.NOT_VERIFIED",
        name: "not_verified",
        checked: filter?.status?.split(",")?.includes("NOT_VERIFIED"),
        count: 0,
      },
      {
        label: "STATUS.SUSPENDED",
        name: "is_suspended",
        checked: filter?.status?.split(",")?.includes("IS_SUSPENDED"),
        count: 0,
      },
    ],
    textSearch: filter?.search ?? "",
  });

  const [filterValue, setFilterValue] = useState({
    status: filter?.status ?? "",
    search: filter?.search ?? "",
    page: currentPage,
  });
  const filterValueDebounced = useDebounce(filterValue, 300);

  const checkboxList = useMemo(() => {
    return withSearch?.checkboxList?.map((current) => ({
      ...current,
      count:
        count_by_status?.[current.name?.toUpperCase()]?.count ||
        current.count ||
        0,
    }));
  }, [withSearch?.checkboxList, count_by_status]);

  const formatFileData = useCallback((fileJson: any): AccountType[] => {
    const filteredJson = fileJson
      .map((json) => Object.values(json))
      ?.map((item, key): AccountType => {
        const lang = String(item[11] ? item[11] : "");
        let language =
          LANG_MAPPING[
            (lang.toLowerCase() === "(no value)" ? "" : lang)?.toLowerCase()
          ];
        const country = String(item[6] ? item[6] : "");
        const country_code =
          PHONE_DATA_MAPPING[
            country.toLowerCase() === "(no value)" ? "" : country
          ] ?? "";
        const price = String(item[0] ? item[0] : "");
        const name = String(item[1] ? item[1] : "");
        const address = String(item[2] ? item[2] : "");
        const city = String(item[3] ? item[3] : "");
        const province = String(item[4] ? item[4] : "");
        const zip = String(item[5] ? item[5] : "");
        const userlast = String(item[7] ? item[7] : "");
        const userfirst = String(item[8] ? item[8] : "");
        const email = String(item[9] ? item[9] : "");
        const phone = String(item[10] ? item[10] : "");
        const account = {
          id: key,
          price_name: price.toLowerCase() === "(no value)" ? "" : price,
          name: name.toLowerCase() === "(no value)" ? "" : name,
          address: address.toLowerCase() === "(no value)" ? "" : address,
          city: city.toLowerCase() === "(no value)" ? "" : city,
          province_code:
            (country_code !== "FR" && country_code !== "BE")
              ? PROVINCE[
                  slugify(
                    province.toLowerCase() === "(no value)" ? "" : province
                  ).toLowerCase()
                ]
              : "",
          zip: zip.toLowerCase() === "(no value)" ? "" : zip.toUpperCase(),
          country_code,
          user_lastname:
            userlast.toLowerCase() === "(no value)" ? "" : userlast,
          user_firstname:
            userfirst.toLowerCase() === "(no value)" ? "" : userfirst,
          user_email: email.toLowerCase() === "(no value)" ? "" : email,
          business_phone: internationalPhone(
            phone.toLowerCase() === "(no value)" ? "" : phone,
            country_code,
            false
          ),
          lang: language,
        };

        account["isValid"] =
          !!account?.price_name &&
          !!account?.name &&
          !!account?.address &&
          !!account?.zip &&
          isZipValid(account?.zip, account?.country_code) &&
          !!account?.city &&
          !!account?.country_code &&
          !!account?.business_phone &&
          isValidNumberForRegion(
            account?.business_phone,
            account?.country_code
          ) &&
          !!account?.user_lastname &&
          !!account?.user_firstname &&
          !!account?.user_email &&
          isValidEmail(account?.user_email) &&
          !!account?.lang;
        //if the country is Canada or Us we should verify if the province code is set

        if (account?.country_code != "FR") {
          if (
            account?.country_code === "US" &&
            !US_STATES_CODE.includes(account?.province_code)
          ) {
            account["isValid"] = false;
          } else if (
            account?.country_code === "CA" &&
            !PROVINCES_CA.includes(account?.province_code)
          )
            account["isValid"] = false;
        }

        return account;
      });

    const result = markDuplicates(filteredJson);
    return result;
  }, []);

  const readFile = useCallback(async (file: File) => {
    setHasFile(true);

    const workbook = read(await file.arrayBuffer());
    const fileJson = utils.sheet_to_json(
      workbook.Sheets[workbook.SheetNames[0]],
      { defval: false }
    );

    if (fileJson?.length) {
      const formatedData = formatFileData(fileJson);
      if (formatedData?.length) {
        if (
          formatedData?.filter((item) => !item.isValid || item.hasDuplicate)
            ?.length === 0
        ) {
          updateAccounts({
            sendContacts: true,
          });
        }
        updateAccounts({
          newContacts: formatedData,
        });
      }
    }
  }, []);

  const downloadModel = useCallback(() => {
    const filename =
      preferredLanguage === "en"
        ? "/template-file.xlsx"
        : "/fichier-modele.xlsx";
    window.location.href = filename;
  }, [preferredLanguage]);

  useEffect(() => {
    setFilterValue((prev) => ({
      ...prev,
      status: withSearch?.checkboxList
        ?.filter((check) => check.checked)
        ?.map((check) => check.name?.toUpperCase())
        .join(","),
      search: withSearch?.textSearch ?? "",
      page: 0,
    }));
  }, [withSearch?.checkboxList, withSearch?.textSearch]);

  useEffect(() => {
    updateAccounts({
      filter: {
        status: filterValueDebounced?.status ?? "",
        search: filterValueDebounced?.search ?? "",
      },
      currentPage: filterValueDebounced?.page,
    });
  }, [filterValueDebounced?.status, filterValueDebounced?.search]);

  return (
    <Paper
      data-testid="topbar-container"
      sx={{
        padding: "8px",
        "@media (min-width: 1024px)": {
          padding: "8px 16px",
        },
      }}
    >
      <Stack
        flexDirection={{
          md: "row",
          sm: "column",
        }}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={{
          sm: "8px",
          xs: "8px",
        }}
      >
        <SearchInputFilter
          withSearch={{
            ...withSearch,
            checkboxList,
          }}
          setWithSearch={setWithSearch}
        />
        {!isSuperAdmin && (
          <PopoverButton
            buttonEndIcon={<Icon name="chevronDownWhite" />}
            buttonIcon={<Add />}
            label={t("COMPANIES.NEW_COMPANY")}
            buttonSx={{
              width: "100%",
              "@media (min-width: 1024px)": {
                width: "fit-content",
              },
            }}
            sx={{
              borderRadius: 8,
            }}
          >
            <Stack
              gap={1}
              flexDirection={"column"}
              style={{
                width: "fit-content",
                minWidth: 262,
              }}
              justifyContent={"flex-start"}
              alignContent={"flex-start"}
              alignItems={"flex-start"}
            >
              <Button
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  fontSize: 15,
                  minWidth: "100%",
                  color: "secondary.main",
                }}
                onClick={() => setOpenUpload(true)}
              >
                {t("COMPANIES.IMPORT_FILE")}
              </Button>
              <Button
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  fontSize: 15,
                  minWidth: "100%",
                  color: "secondary.main",
                }}
                onClick={() => navigate("/accounts/create")}
              >
                {t("COMPANIES.CREATE_ONE_COMPANY")}
              </Button>
            </Stack>
          </PopoverButton>
        )}
      </Stack>
      <UploadFile
        open={openUpload}
        close={() => {
          setOpenUpload(false);
          setHasFile(false);
        }}
        setFile={readFile}
        title={t("MODAL.ADD_FILE")}
        accept=".csv,application/vnd.ms-excel,.xlt,application/vnd.ms-excel,.xla,application/vnd.ms-excel,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xltx,application/vnd.openxmlformats-officedocument.spreadsheetml.template,.xlsm,application/vnd.ms-excel.sheet.macroEnabled.12,.xltm,application/vnd.ms-excel.template.macroEnabled.12,.xlam,application/vnd.ms-excel.addin.macroEnabled.12,.xlsb,application/vnd.ms-excel.sheet.binary.macroEnabled.12,text/csv"
        replace=".csv"
        subTitle={
          <>
            {displayWarning && (
              <Alert
                variant="filled"
                severity="warning"
                icon=""
                elevation={0}
                sx={{
                  backgroundColor: "#FFF4E5",
                  minWidth: "100%",
                  paddingTop: 0,
                  padding: "8px 16px",
                  position: "relative",
                  justifyContent: "flex-start",
                  color: "#663C00",
                  boxShadow: "none",
                  borderRadius: "8px",
                  "& .MuiAlert-icon": {
                    display: "none",
                  },
                  "& .MuiAlert-message": {
                    padding: 0,
                  },
                  "& p:first-of-type": {
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "24px",
                    marginBottom: "6px",
                    marginTop: 0,
                  },
                }}
                closeText="x"
              >
                <IconButton
                  onClick={() => {
                    setDisplayWarning(!displayWarning);
                  }}
                  sx={{
                    position: "absolute",
                    right: "14px",
                    top: "7px",
                    padding: 0,
                    "& svg": {
                      color: "#663C00",
                      fill: "#663C00",
                      width: "20px",
                    },
                  }}
                >
                  <Icon name="close" />
                </IconButton>
                <div
                  dangerouslySetInnerHTML={{ __html: t("MODAL.WARNING_FILE") }}
                ></div>
              </Alert>
            )}

            {!hasFile && (
              <Button variant="contained" fullWidth onClick={downloadModel}>
                {t("MODAL.DOWNLOAD_MODEL")}
              </Button>
            )}
          </>
        }
      >
        <Typography
          sx={{
            fontSize: 12,
            lineHeight: "19px",
            color: "rgba(104, 115, 141, 1)",
          }}
        >
          {t("MODAL.USE_FILE_MODEL")}
        </Typography>
      </UploadFile>
    </Paper>
  );
};

export default TopbarComptes;
