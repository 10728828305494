import TitleWrapper from "@/components/base/page-container/title-wrapper";
import FormAction from "@/components/forms/form-action";
import FormUserMain from "@/components/forms/users/form-user-main";
import FormRole from "@/components/forms/users/form-user-role";
import PaperBloc from "@/components/paper-bloc";
import TopbarEditUser from "@/components/topbar/topbar-edit-user";
import useUiPageSession from "@/hooks/useUiPageSession";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import useEditUser from "./useEditUser";

const EditUserPage = () => {
  useUiPageSession({ title: "Users", page: "user-edit" });
  const { t } = useTranslation();

  const {
    isSubmitting,
    isFormValid,
    handlePrevious,
    handleCancel,
    handleSubmit,
  } = useEditUser();

  return (
    <>
      <TitleWrapper
        title={"USERS.EDIT_USER"}
        handlePreviousButton={handlePrevious}
      />
      <TopbarEditUser />
      <form>
        <Stack gap={3} flexDirection={"row"}>
          <PaperBloc title="USERS.MAIN_INFORMATION" sx={{ width: "100%" }}>
            <FormUserMain />
          </PaperBloc>
          <PaperBloc title="USERS.ROLE" sx={{ width: "100%" }}>
            <FormRole />
          </PaperBloc>
        </Stack>
        <FormAction
          handleCancel={handleCancel}
          isLoading={isSubmitting}
          buttonText={t("FORMS.SAVE")}
          isUpdating
          isDisabled={isSubmitting || !isFormValid}
          handleSubmit={handleSubmit}
        />
      </form>
    </>
  );
};

export default EditUserPage;
