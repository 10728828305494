import IconNames from "@/types/icons";
import { Chip } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Icon from "../Icon";

const StatusChip = ({
  label,
  icon,
  color = "success",
  onClick,
}: {
  label: string;
  icon: IconNames;
  color?: "success" | "warning" | "default" | "primary" | "secondary" | "error";
  onClick?: () => void;
}) => {
  const { t } = useTranslation();

  const triggerClick = useCallback(() => {
    if (typeof onClick === "function") onClick();
  }, [onClick]);

  return (
    <Chip
      data-testid="chip-component"
      icon={<Icon name={icon} />}
      label={t(`STATUS.${label}`)}
      onClick={triggerClick}
      sx={{
        color: "#FFF",
        padding: "3px 4px",
        width: "fit-content",
      }}
      color={color}
    />
  );
};

export default StatusChip;

export const StatusChipRender = (status: string) => {
  const chip: {
    icon: IconNames;
    color: "success" | "default" | "warning" | "secondary" | "error";
    label: string;
  } = {
    icon: "connectedStatus",
    color: "success",
    label: "CONNECTED",
  };

  switch (status) {
      case 'DISCONNECTED':
          chip.icon = "disconnectedStatus"
          chip.color = "error"
          chip.label = "DISCONNECTED"
          break
      case 'IS_SUSPENDED':
          chip.icon = "clockStatus"
          chip.color = "error"
          chip.label = "SUSPENDED"
          break
      case 'EMPTY_COMPANY':
          chip.icon = "companyStatus"
          chip.color = "warning"
          chip.label = "EMPTY_COMPANY"
          break
      case 'DUPLICATED':
          chip.icon = "duplicateStatus"
          chip.color = "error"
          chip.label = "DUPLICATED"
          break
      case 'NOT_VERIFIED':
          chip.icon = "verifiedStatus"
          chip.color = "error"
          chip.label = "NOT_VERIFIED"
          break
  }
  return <StatusChip label={chip.label} icon={chip.icon} color={chip.color} />;
};
