import { CookiesService } from "@/services";
import { ApiService } from "../";
import { ErrorResponse } from "../base";

interface UserData {
  uid: string;
  role: string;
  email: string;
  firstname: string;
  lastname: string;
  gender: string;
  lang: string;
  logo: string;
  is_super_admin: boolean;
  is_switch_account?: boolean;
}

interface VendorData {
  uid: null;
  smsSender: null;
  emailSpotconnect: null;
}

interface Metadata {
  linkUrl: string;
}

export interface UserLoginResponse {
  user: {
    uid: string;
    role: string;
    email: string;
    firstname: string;
    lastname: string;
    gender: null;
    lang: string;
    logo: string;
    is_super_admin: boolean;
    is_switch_account?: boolean;
  };
  vendor: {
    uid: string;
    smsSender: string;
    emailSpotconnect: string;
  };
  metadata: {
    linkUrl: string;
  };
  tokens: {
    token: string;
    refresh_token: string;
  };
}

class UserApi {
  connectUser = async (
    email: string,
    password: string,
    authType = "default"
  ): Promise<UserLoginResponse & Partial<ErrorResponse>> => {
    if (authType === "google") {
      return ApiService.post(`/user-login`, {
        client_id: email,
        credential: password,
      });
    }
    return ApiService.post(`/user-login`, { username: email, password });
  };

  resetPassword = async (email: string, lang: string) => {
    return ApiService.post(`/reset-password`, { email, lang });
  };

  initPassword = async (userInfos: object) => {
    return ApiService.post(`/init-password`, userInfos);
  };

  fetchListUser = async ({ page_number, page_size, user_uid }) => {
    return ApiService.get(`/users`, {
      page_number,
      page_size,
      user_uid,
      reseller_uid: CookiesService.get("resellerUid"),
    });
  };

  fetchUser = async ({ userId, user_uid }) => {
    return ApiService.get(`/user-admin/${userId}`, { user_uid });
  };

  deleteUser = async ({ userId, user_uid }) => {
    return ApiService.delete(`/user-admin/${userId}`, {
      params: { user_uid },
    });
  };

  createUser = async (user_uid: string, userInfos: any) => {
    const userObject = {
      user_uid,
      firstname: userInfos.firstname,
      lastname: userInfos.lastname,
      email: userInfos.email,
      lang: userInfos.lang,
    };
    return ApiService.post(`/user-admin`, userObject);
  };

  updateUser = async (user_uid: string, userInfos: any) => {
    const userObject = {
      user_uid,
      firstname: userInfos.firstname,
      lastname: userInfos.lastname,
      email: userInfos.email,
      lang: userInfos.lang,
    };
    return ApiService.put(`/user-admin/${userInfos.user_uid}`, userObject);
  };

  resendAccess = async ({ user_uid, email, lang }) => {
    return ApiService.post(`/user-send-login`, { user_uid, email, lang });
  };

  fetchListUserAdmin = async ({ page_number, page_size, user_uid }) => {
    return ApiService.get(`/users-admin`, {
      page_number,
      page_size,
      user_uid,
    });
  };
  promoteUser = async ({
    user_uid,
    reseller_uid,
  }: {
    user_uid: string;
    reseller_uid: string;
  }): Promise<UserLoginResponse> => {
    return ApiService.post(`/user-promote`, {
      user_uid,
      reseller_uid,
    });
  };

  demoteUser = async ({ user_uid }: { user_uid: string }) => {
    return ApiService.post(`/user-demote`, {
      user_uid,
    });
  };
}

export default new UserApi();
