import env from "@/config/env";
import {
  CookiesService,
  ErrorHandlerRequestService,
  UserSessionService,
} from "@/services";
import UserSessionStore from "@/store/user-session";
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  CreateAxiosDefaults,
} from "axios";

export interface ErrorResponse {
  error: string;
  status_code?: number;
}

export class ApiService {
  client: AxiosInstance;
  constructor(axiosOptions: CreateAxiosDefaults) {
    this.client = axios.create(axiosOptions);
    this.interceptor();
  }

  setToken = (token: string, refreshToken: string) => {
    CookiesService.set({
      token,
      refreshToken,
    });
  };

  getToken = () => {
    const { token, refreshToken } = CookiesService.getAll();
    return {
      token,
      refreshToken,
    };
  };

  clearToken = () => {
    CookiesService.delete(["token", "refreshToken"]);
  };

  refreshToken = async () => {
    try {
      const { token, refreshToken } = this.getToken();
      const response = await this.post(
        "/api/token/refresh",
        {
          token,
          refresh_token: refreshToken,
        },
        { baseURL: env.APPBACK_API_PATH }
      );
      return {
        token: response?.token,
        refreshToken: response?.refreshToken,
      };
    } catch (e) {
      return {
        token: null,
        refreshToken: null,
      };
    }
  };

  invalidateToken = async () => {
    const { token, refreshToken } = this.getToken();
    if (token) {
      delete this.client.defaults.headers.common["Authorization"];
      this.clearToken();
      await this.post("/token/invalidate", {
        token,
        refresh_token: refreshToken,
      });
    }
  };

  getVerifySession = () => {
    const { profile } = UserSessionStore.getState();
    const { user_uid } = CookiesService.getAll();
    if (user_uid && profile?.uid && profile?.uid !== user_uid) {
      return { sessionChanged: true };
    }
    return { sessionChanged: false };
  };

  interceptor = () => {
    this.client.interceptors.request.use(
      (config) => {
        const { token } = this.getToken();
        const { sessionChanged } = this.getVerifySession();
        if (sessionChanged) {
          window.location.reload();
        }
        if (token && !sessionChanged) {
          config.headers.Authorization = `Bearer ${token}`;
        } else {
          config.headers.Authorization = "";
        }
        return config;
      },
      async (error) => {
        const { token } = this.getToken();
        const originalRequest = error.config;
        if (error.response.status === 401 && token) {
          if (!originalRequest._retry) {
            originalRequest._retry = true;
            const { token, refreshToken } = await this.refreshToken();
            this.setToken(token, refreshToken);
            if (token) {
              this.client.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${token}`;
              return this.client(originalRequest);
            }
            return UserSessionService.logout();
          }
          ErrorHandlerRequestService.unHandledRequestError(error);
        }
        return Promise.reject(error);
      }
    );
  };

  post = async (
    url: string,
    body: any = {},
    config: AxiosRequestConfig = {}
  ) => {
    return ErrorHandlerRequestService.asyncHandlerRequestError(async () => {
      const response = await this.client.post(url, body, config);
      return response?.data;
    });
  };

  postFile = async (
    url: string,
    file: any,
    body: any = {},
    config: AxiosRequestConfig = {}
  ) => {
    const formData = new FormData();
    formData.append("reseller_id", body.reseller_id);
    formData.append("file", file);
    return ErrorHandlerRequestService.asyncHandlerRequestError(async () => {
      const response = await this.client.post(url, formData, {
        ...config,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response?.data;
    });
  };

  postFiles = async (
    url: string,
    files: any,
    body: any = {},
    config: AxiosRequestConfig = {}
  ) => {
    const formData = new FormData();
    formData.append("reseller_id", body.reseller_id);
    files.map((file) => {
      formData.append("file[]", file);
    });
    return ErrorHandlerRequestService.asyncHandlerRequestError(async () => {
      const response = await this.client.post(url, formData, {
        ...config,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });
      return response?.data;
    });
  };

  get = async (
    url: string,
    params: any = {},
    config: AxiosRequestConfig = {}
  ) => {
    return ErrorHandlerRequestService.asyncHandlerRequestError(async () => {
      const response = await this.client.get(url, { params, ...config });
      return response?.data;
    });
  };

  put = async (
    url: string,
    body: any = {},
    config: AxiosRequestConfig = {}
  ) => {
    return ErrorHandlerRequestService.asyncHandlerRequestError(async () => {
      const response = await this.client.put(url, body, config);
      return response?.data;
    });
  };

  delete = async (url: string, config: AxiosRequestConfig = {}) => {
    return ErrorHandlerRequestService.asyncHandlerRequestError(async () => {
      const response = await this.client.delete(url, config);
      return response?.data;
    });
  };
}

export default new ApiService({
  baseURL: `${env.APPBACK_API_PATH}`,
});
