import { SxProps } from "@mui/material";
import { CountryCode } from "libphonenumber-js";
import { MuiTelInput } from "mui-tel-input";

interface BaseInputOptionalProps {
  value: string;
  lang: string;
  error: boolean;
  errorLabel: string;
  onChange: (info: any, value: string) => void;

  required: boolean;
  disabled: boolean;
  onlyCountries: CountryCode[];
  excludedCountries: CountryCode[];

  inputRef: any;
  defaultCountry: CountryCode;
}

export interface InfoType {
  countryCallingCode: string;
  countryCode: CountryCode;
  nationalNumber: string;
  numberType: string;
  numberValue: string;
  reason: string;
}

export interface BaseInputPhoneProps extends Partial<BaseInputOptionalProps> {
  label: string;
  name: string;
}

export const sx: SxProps = {
  borderRadius: "8px",
  "& .MuiButtonBase-root": {
    padding: 0,
  },
  "& .MuiInputBase-root": {
    paddingTop: "3px",
  },
  "& .MuiFilledInput-input": {
    paddingTop: "22px",
  },
  "& img": {
    width: 24,
    height: 18,
    objectFit: "contain",
  },
  "& fieldset": {
    border: "none",
  },
  "body & .MuiFilledInput-root": {
    backgroundColor: "rgba(172, 182, 229, 0.16)",
    "&::after, &:hover:before, &::before": {
      borderBottom: "1px solid rgba(91, 125, 216, 0.12)",
    },
    "&.Mui-error": {
      "&::after, &:hover:before, &::before": {
        borderBottom: "1px solid",
        borderBottomColor: "error.main",
      },
    },
  },
  "& label, & label.Mui-focused": {
    color: "action.active",
  },
  "& label.Mui-error, & label.Mui-focused.Mui-error": {
    color: "error.main",
  },
  "& .MuiFilledInput-root, & .MuiFilledInput-root::after, & .MuiFilledInput-root.Mui-focused::after":
    {
      borderRadius: "8px",
      overflow: "hidden",
    },
  "& .MuiFormLabel-asterisk": {
    color: "primary.main",
    "&.Mui-error": {
      color: "error.main",
    },
  },
  "& p:not(.Mui-error), & input": {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "24px",
  },
};

const BaseInputPhone = ({
  label,
  onlyCountries = [],
  inputRef,
  value,
  lang,
  required = false,
  error = false,
  errorLabel,
  defaultCountry = "CA",
  disabled = false,
  onChange,
}: BaseInputPhoneProps) => {
  return (
    <MuiTelInput
      value={value}
      sx={sx}
      label={label}
      required={required}
      inputRef={inputRef}
      data-testid="input-phone"
      defaultCountry={defaultCountry}
      onlyCountries={onlyCountries}
      forceCallingCode
      disabled={disabled}
      InputProps={{
        sx: {
          "& .MuiInputAdornment-root": {
            marginRight: 0.5,
            "&.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)":
              {
                marginTop: "12px !important",
              },
          },
          "& p.MuiTypography-root": {
            marginTop: "2px",
            marginLeft: 1,
            paddingRight: 0,
            borderRight: "none",
            color: "secondary.main",
          },
        },
      }}
      variant="filled"
      MenuProps={{
        slotProps: {
          paper: {
            sx: {
              padding: 0,
            },
          },
          root: {
            sx: {
              "& .MuiBackdrop-root": {
                opacity: "0 !important",
              },
            },
          },
        },
      }}
      langOfCountryName={lang}
      helperText={error ? errorLabel : ""}
      error={error}
      onChange={(value: string, info: InfoType) => {
        if (typeof onChange === "function") {
          onChange(info, value);
        }
      }}
    />
  );
};

export default BaseInputPhone;
