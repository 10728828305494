import { CountryCode } from "libphonenumber-js";

export const PHONE_COUNTRIES: CountryCode[] = ["CA", "US", "FR", "BE"];

export const PHONE_DATA_MAPPING = {
  // Canada
  Canada: "CA",
  canada: "CA",
  CA: "CA",
  ca: "CA",

  // États-Unis / United States
  "Etats-unis": "US",
  "États-unis": "US",
  "etats-unis": "US",
  "états-unis": "US",
  "Etats-Unis": "US",
  "États-Unis": "US",
  "United States": "US",
  "united states": "US",
  USA: "US",
  usa: "US",
  US: "US",
  us: "US",
  "U.S.A.": "US",
  "u.s.a.": "US",
  "U.S.": "US",
  "u.s.": "US",

  // France
  France: "FR",
  france: "FR",
  FR: "FR",
  fr: "FR",
};

export const LANG_MAPPING = {
  francais: "fr-ca",
  français: "fr-ca",
  anglais: "en",
  english: "en",
  french: "fr-ca",
  fr: "fr-ca",
  en: "en",
  "fr-ca": "fr-ca",
  "fr-fr": "fr-ca",
  "en-us": "en",
  "en-gb": "en",
  "en-ca": "en",
  fr_CA: "fr-ca",
  en_US: "en",
  en_GB: "en",
  en_CA: "en",
  Francais: "fr-ca",
  Français: "fr-ca",
  Anglais: "en",
  English: "en",
  French: "fr-ca",
  FR: "fr-ca",
  EN: "en",
  "FR-CA": "fr-ca",
  "FR-FR": "fr-ca",
  "EN-US": "en",
  "EN-GB": "en",
  "EN-CA": "en",
  "Français (Canada)": "fr-ca",
  "French (Canada)": "fr-ca",
  "English (United States)": "en",
  "English (United Kingdom)": "en",
  "English (Canada)": "en",
};
