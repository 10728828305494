import useAccountsStore from "@/store/accounts";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

const CompaniesLayout = () => {
  
  const {flush: flushAccountStore} = useAccountsStore()

  useEffect(() => {
    return () => {
      flushAccountStore()
    }
  }, [flushAccountStore])

  return (
    <Box>
      <Outlet />
    </Box>
  );
};

export default CompaniesLayout;
